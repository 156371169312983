import config from '../tokens/config.json';
import './alert/dashboard.js';
import './dropdown/init.js';
import './flatpickr/init.js';
import { Loader } from './loader.js';
import './select/init.js';
import Select from './select/Select.js';
import Menu from './menu.js';
import './tooltip/init.js';
import 'preline';
import Quill from 'quill';
import { quillConfig } from './utils.js';

import hugerte from 'hugerte';
import 'hugerte/models/dom';
import 'hugerte/icons/default';
import 'hugerte/themes/silver';
import 'hugerte/skins/ui/oxide/skin.js';
import 'hugerte/skins/ui/oxide/content.js';
import 'hugerte/skins/content/default/content.js';
import 'hugerte/plugins/accordion';
import 'hugerte/plugins/advlist';
import 'hugerte/plugins/anchor';
import 'hugerte/plugins/autolink';
import 'hugerte/plugins/autoresize';
import 'hugerte/plugins/autosave';
import 'hugerte/plugins/charmap';
import 'hugerte/plugins/code';
import 'hugerte/plugins/codesample';
import 'hugerte/plugins/directionality';
import 'hugerte/plugins/fullscreen';
import 'hugerte/plugins/image';
import 'hugerte/plugins/insertdatetime';
import 'hugerte/plugins/link';
import 'hugerte/plugins/lists';
import 'hugerte/plugins/media';
import 'hugerte/plugins/nonbreaking';
import 'hugerte/plugins/pagebreak';
import 'hugerte/plugins/preview';
import 'hugerte/plugins/quickbars';
import 'hugerte/plugins/save';
import 'hugerte/plugins/searchreplace';
import 'hugerte/plugins/table';
import 'hugerte/plugins/template';
import 'hugerte/plugins/visualblocks';
import 'hugerte/plugins/visualchars';
import 'hugerte/plugins/wordcount';
import 'hugerte/plugins/emoticons';
import 'hugerte/plugins/emoticons/js/emojis';
import 'hugerte/plugins/help';
import 'hugerte/plugins/help/js/i18n/keynav/en.js';
import 'hugerte/plugins/help/js/i18n/keynav/de.js';

window.accordion = Select;
globalThis.accordion = Select;

new Loader().hide();
Menu('menu');

const toggle = (navbar, aside, asideWidth, button, main) => {
    button.addEventListener('click', () => {
        aside.classList.toggle('active');
        aside.classList.toggle('navbars-aside--sm');
        aside.classList.toggle('navbars-aside--full');
        asideWidth = aside.offsetWidth;
        main.style.setProperty('--aside-leftbar-width', asideWidth + 'px');
        navbar.style.setProperty('--aside-leftbar-width', asideWidth + 'px');

        const dropdownItems = aside.querySelectorAll('.dropdown__items');

        dropdownItems.forEach((item) => {
            item.classList.remove('open');
        });

        button.querySelector('i').style.transform =
            button.querySelector('i').style.transform === 'rotate(180deg)'
                ? ''
                : 'rotate(180deg)';
    });
};

const resize = (aside, navbar, main) => {
    window.addEventListener('resize', () => {
        const navbarAsideWidth = aside.offsetWidth;
        navbar.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );
        main.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );
    });
};
const showPassword = () => {
    const button = document.querySelector(`#password-show-password`);
    button.addEventListener('click', () => {
        const input = document.getElementById(`password`);
        input.type = input.type === 'password' ? 'text' : 'password';
        const icon = button.querySelector(`i`);
        icon.classList.toggle('icons-eye-slash');
        icon.classList.toggle('icons-eye');
    });
};

window.onload = () => {
    import('./tables/init.js')
        .then((module) => {
            module;
            document.querySelectorAll('.dt-input').forEach((element) => {
                new Select(element, {
                    showSearch: true,
                });
            });
        })
        .catch((error) => {
            console.error("Erreur lors de l'import du module :", error);
        });
   
    if (document.querySelector(`#password-show-password`)) {
        showPassword();
    }
};

document.addEventListener('DOMContentLoaded', () => {

    const navbar = document.querySelector('[data-navbars="dashboard"]');
    const navbarAside = document.querySelector('.navbar-aside');
    const navbarToggle = document.querySelector(
        '[data-navbars="dashboard"] .toggle-menu'
    );
    const main = document.querySelector('main');
    let navbarAsideWidth;

    if (navbarAside) {
        // navbarAside.classList.add('navbars-aside--sm');
        navbarAsideWidth = navbarAside.offsetWidth;
        navbar.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );
        main.style.setProperty(
            '--aside-leftbar-width',
            navbarAsideWidth + 'px'
        );

        resize(navbarAside, navbar, main);
        toggle(navbar, navbarAside, navbarAsideWidth, navbarToggle, main);
    }

})

if (document.querySelector('.iframe-scroll')) {
    document.querySelector('.iframe-scroll').style.top = `${window.scrollY}px`;
    window.addEventListener('scroll', function(e) {
        document.querySelector('.iframe-scroll').style.top = `${window.scrollY}px`;
    });
}

if (document.getElementById('formTextEditor')) {
    document.getElementById('formTextEditor').addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        HSOverlay.close(document.getElementById('modalTextEditor'));

        if (document.getElementById('formTextEditor').__quillUpdate) {
            document.getElementById('formTextEditor').__quillUpdate(document.getElementById('formTextEditor').__quill.getContent({ format: 'html' }));
        }
        
    });
}

if (document.querySelector('#formTextEditor .text-editor-global')) {
    // const quill = new Quill(document.querySelector('#formTextEditor .text-editor-global'), {
    //     ...quillConfig
    // });


    
    // quill.clipboard.addMatcher(Node.ELEMENT_NODE, function (node, delta) {
    //     delta.ops.forEach((val, index) => {
    //         if (delta.ops[index].attributes) {
    //             if (delta.ops[index].attributes.background) delete delta.ops[index].attributes.background;
    //             if (delta.ops[index].attributes.color) delete delta.ops[index].attributes.color;
    //         }
           
    //         if (delta.ops[index].insert.image) {
    //             delete delta.ops[index];
    //         }
    //     })

    //     delta.ops = Object.values(delta.ops);

    //     return delta;
    // });

    // quill.on('text-change', (delta, oldDelta, source)  => {
    //     document.querySelector('#formTextEditor .text-quill-global').value = quill.getSemanticHTML();
    // });

    hugerte.init({
        target: document.querySelector('#formTextEditor .text-editor-global'),
        skin_url: 'default',
        content_css: 'default',
        height: 300,
        menubar: false,
        toolbar_mode: 'wrap',
        plugins: 'advlist autolink lists link charmap preview anchor searchreplace emoticons',
        fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
        quickbars_selection_toolbar: false,
        toolbar: `undo redo |
            bold italic underline strikethrough link | forecolor backcolor | alignleft aligncenter
            alignright aligncenter alignjustify | fontsize | bullist numlist outdent indent | emoticons `,
        language: 'fr_FR',
        language_url: '/libs/hugerte/fr_FR.js',
        setup: function (editor) {
            editor.on('input change', function () {
                document.querySelector('#formTextEditor .text-quill-global').value = editor.getContent({ format: 'html' });
            });
        }
    });

    document.querySelector('#formTextEditor').__quill = hugerte.get('textEditorContent');
}
