import Select from './Select.js';

document.querySelectorAll('[data-select]').forEach((element) => {
    const value = element.dataset['select'];

    switch (value) {
        case 'test':
            console.log(`hello ${value}`);
            break;

        case 'languages':
            const selectLang = new Select(element, {
                showSearch: true,
                hideSelected: false,
            });
            element.__select = selectLang;
            break;

        case 'mutiple':
            const selectMult = new Select(element, {
                showSearch: true,
                hideSelected: false,
                closeOnSelect: false,
                allowDeselect: true,
                multiple: true,
            });
            element.__select = selectMult;
            break;

        case 'mutiple_search':
            const selectMultSearch = new Select(element, {
                showSearch: true,
                hideSelected: false,
                allowDeselect: true,
                closeOnSelect: false,
                multiple: true,
            });
            element.__select = selectMultSearch;
            break;

        case 'search':
            const selectSearch = new Select(element, {
                showSearch: true,
                hideSelected: false,
                closeOnSelect: true,
                allowDeselect: true,
                multiple: false,
            });
            element.__select = selectSearch;
            break;
        default:
            const select = new Select(element);
            element.__select = select;
            break;
    }
});
